import { useSelector } from "react-redux";
import store from "../../store";
import { adjustView, DataCheck } from "./data";
import { useEffect } from "react";
import './style.css'

export function isHVMapped(plantName){
    const {HVMap} = store.getState()['options']
    return HVMap[plantName] !== undefined
}
store.subscribe(isHVMapped)

export function HVMapping(props){
    const {HVMap} = useSelector((state) => state.options)
    const {plantName, data} = props

    
    const PlantMap = ()=>{
        console.log(HVMap)
        if (HVMap[plantName] !== undefined){
            const Map = HVMap[plantName].svg
            return <Map></Map>
        }else{
            return <h2>No mapping yet</h2>
        }
        

    }

    useEffect(()=>{
        if (data){
            adjustView(data)
        }
        return ()=>{
        if (data){
            adjustView(data)
        }
    }})
    console.log('render')
    
    return <div className="netWork">
            <DataCheck data={data}></DataCheck>
            <PlantMap id="plantMap"></PlantMap>
        </div>

}