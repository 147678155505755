import { HorizontalBar } from "../../../components/HorizontalBar"
import "./bigcard.css"
import { useSelector } from "react-redux";

// const transformWarning = (warn) => {
//     const x = (warn).toString(2)
//     const k = x.length
//     let t = ""
//     let c = 0

//     while (c < 16){
//         if (c < 16-k){
//             t = t + '0'
//         }else{
//             const i = -(16 -c - k)
//             // console.log(i)
//             t = t + x[i]
//         }
//         c = c + 1
//     } 
    
//     return t
// }



export function BigCard(props){
    const {elementName, plantName, info} = props
    const {options} = useSelector((state =>({options: state.options})))

    const inverterType = options.plantInvertersType[plantName]
    const warnMapping = options.faultMap[inverterType]
    const {maxISB, isbsExclusionList} = options

    const translate = (variable, data)=>{
        
        const map = warnMapping[variable]


        if (map){
            if (map[data]){
                return map[data] + ', '
            }
            var alarms = []
            const str = (data.toString(2)).padStart(32,'0')

            for (let i = 0; i < str.length; i++) {
                if (str.charAt(i) === "1"){
                    const pow = 31 - i
                    const val = 2**pow
                    if (map[val]){
                        alarms.push(map[val])
                    }else{
                        alarms.push(variable + " - " + val.toString())
                    }
                }
            }

            if (alarms.length > 0) {
                return alarms.join(', ')
            }
            return "Unknown code - "  + variable + " - " + data.toString() + ', '
        }else{
            return "Unknown code - " + variable + " - " + data.toString()
        }
        
    }

    if (elementName){
        // console.log(Object.keys(info))
        const ISBs = Object.keys(info).map((v, i) => {
            
            if (v.includes('I_SB')){
                return v
            }else{
                return undefined
            }

        })
        
        const exclusions = (plantName in isbsExclusionList)? isbsExclusionList[plantName].default: []

        const options = ISBs.map((sb, i) => {
            const mx = (plantName in maxISB)? maxISB[plantName]:maxISB.default
            // console.log(mx)
            if ((sb)&&(info[sb] !== undefined)&&(!(exclusions.includes(sb)))){
                return <HorizontalBar comms = {info.comms === 0} onClick = {()=>{}} value={info[sb]} minValue={0} maxValue={mx} title={sb+ ' - ' + info[sb]}></HorizontalBar>
            }
            return undefined
        })

        let wcount = 0
        const warn = Object.keys(warnMapping)
        let warnings = warn.map((wn, i) => {
            if (info){
                // console.log(wn)
                if(info[wn] > 0){
                    wcount = wcount + 1
                    return <div>{
                        translate(wn, info[wn])
                        }
                         {/* - code ({wn} - {info[wn]}) */}
                        </div>
                }
            }
            return undefined
        })  

        if (wcount === 0){
            warnings = <div>No warnings</div>
        }
        
        return <div className="bigcard border">
    
            <div className="bigcard-info">
                <div className="sb-data">
                    String Boxes
                    <div id="sb-list">
                        {options}
                    </div>
                    
                    <div/>
                    
                </div>
                <div className="warnings">
                    {warnings}
                </div>
            </div>
        </div>
    }else{
        return <div className="bigcard">
        </div>
    }
    
}