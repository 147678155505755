import {createSlice} from '@reduxjs/toolkit'

const zip = (a, b, c) => a.map((k, i) => [k, b[i]]);

function AddNewSample(state, action){
    let new_state = state
    const key = action.context.topic

    

    Object.keys(action.payload).forEach(elementName => {

        if (!(key in new_state)){
            new_state[key] = {}
        }
        if (!(elementName in new_state[key])){
            new_state[key][elementName] = {ts: 0, comms: undefined}
        }
        
        if (action.payload[elementName].vars === undefined){
            return new_state[key][elementName]
        }
        
        new_state[key][elementName].comms = action.payload[elementName].comms
        if (action.payload[elementName].ts >= new_state[key][elementName].ts){
            new_state[key][elementName].ts = action.payload[elementName].ts
        }
        zip(action.payload[elementName].vars, action.payload[elementName].values).forEach((data) => { 
            const [vars, vals] = data
            if (!(vars in new_state[key][elementName])){
                new_state[key][elementName][vars] = undefined 
            }
            
            new_state[key][elementName][vars] = vals
        })

        

    })
    return new_state
}


export const mqttSlice = createSlice({
    name: 'mqtt',
    initialState: {},
    reducers: {
         ADD_SAMPLE: AddNewSample
    }
})

export const {ADD_SAMPLE} = mqttSlice.actions
export const MQTTReducer = mqttSlice.reducer