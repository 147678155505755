import "./home.css"

import { CustomHeader } from "../../components/CustomHeader"
import MQTTComponent from "../../components/MQTT"

import {  useSelector } from "react-redux"
import { getTopicName } from "../../utils"



function getHtml(title, data){
    return <div className="show-stoppage">
            <h1>{title}</h1>
            <div className="stoppage-content">
            {
                Object.keys(data).map(plantName => {
                    return <div className="plant-stoppages">
                        <h2>{plantName}</h2>
                        <div className="inverters-list">
                        {data[plantName]}
                        </div>
                    </div>
                })
            }
            </div>
           
    </div>
}

function checkWeather(plantName, mqtt){
    if (plantName === 'Javiera'){
        return false
    }

    const key = getTopicName(plantName, 'WS')
    const data = key in mqtt ? mqtt[key] : {}

    let counter = 0
    let summ = 0

    let elements = []
    if (data){
        elements = Object.keys(data)
    }
    
    elements.forEach((elementName)=>{
        const info = elementName in data ? data[elementName] : {}

        if(info['comms'] === 0){
            const n = info['g1']?1:0 + info['g2']?1:0 + info['g3']?1:0 

            summ = summ + (info['g1']?info['g1']:0 + info['g2']?info['g2']:0 + info['g3']?info['g3']:0)/n
            counter = counter + 1
        }
    })

    console.log(summ, counter)
    
    if (elements.length === 0){
        const d = new Date();
        const hour = d.getHours()
        if  (hour >= 6 & hour <= 18){
            return true
        }
        
    }else if(counter > 0 & summ/counter >= 25){
        return true
    }


    
    return false
}

export function HomePage(){
    const {options} = useSelector(state => state)
    const {plantsNames} = options
    const {plantInvertersType, faultMap} = useSelector(state => ({mapMax: state.options.mapMax, plantInvertersType:state.options.plantInvertersType, faultMap:state.options.faultMap}))
    const mqtt = useSelector(state => state.mqtt)

    let stoppedHtml = {}
    let nocommsHtml = {}

    plantsNames.forEach((plantName) =>{
        const hasIrradiance = checkWeather(plantName, mqtt)

        if (hasIrradiance){
            const key = getTopicName(plantName, 'Inverter')
            const data = key in mqtt ? mqtt[key] : {}
            console.log(key, data)

            

            const inverterType = plantInvertersType[plantName]
            const warnMapping = faultMap[inverterType]

            let elements = []
            if (data){
                elements = Object.keys(data)
            }

            const translate = (variable, data)=>{
                const map = warnMapping[variable]
                if (map){
                    if (map[data]){
                        return map[data] + ', '
                    }
                    var alarms = []
                    const str = (data.toString(2)).padStart(32,'0')
        
                    for (let i = 0; i < str.length; i++) {
                        if (str.charAt(i) === "1"){
                            const pow = 31 - i
                            const val = 2**pow
                            if (map[val]){
                                alarms.push(map[val])
                            }else{
                                alarms.push(val.toString())
                            }
                        }
                    }
        
                    if (alarms.length > 0) {
                        return alarms.join(', ')
                    }
                    return "Unknown code - "  + variable + " - " + data.toString() + ', '
                }else{
                    return "Unknown code - " + variable + " - " + data.toString()
                }
                
            }
            
            elements.forEach((elementName)=>{
                    const info = elementName in data ? data[elementName] : {}

                    let wcount = 0
                    const warn = Object.keys(warnMapping)
                    let warnings = warn.map((wn, i) => {
                        if (info){
                            // console.log(wn)
                            if(info[wn] > 0){
                                wcount = wcount + 1
                                return translate(wn, info[wn])
                            }
                        }
                        
                    })  

                    if (wcount === 0){
                        warnings = 'No warning'
                    }


                    if (info['comms'] > 0){
                        if (nocommsHtml[plantName] === undefined){
                            nocommsHtml[plantName] = []
                        }
                        nocommsHtml[plantName].push(<p><b>{elementName} </b>- {warnings}</p>)
                    }else if(info['P'] <=0.01){
                        if (stoppedHtml[plantName] === undefined){
                            stoppedHtml[plantName] = []
                        }
                        stoppedHtml[plantName].push(<p><b>{elementName} </b>- {warnings}</p>)
                    }
                })

        }        
    })
        


    return <>
        <CustomHeader></CustomHeader>
        <MQTTComponent val='all'></MQTTComponent> 
        <div id='grid-content'>
            {getHtml("Inverter stoppage", stoppedHtml)}
            {getHtml("Missing comms", nocommsHtml)}
        </div>
        
        
        {/* <div id='home'>
            {
                plantsNames.map((plantName)=>{
                    return <ShowData  context={{plantName, elementType:'Inverter'}}></ShowData>
                })
            }
        </div> */}
    </>
    // <div className="bg-image">
    //     <div id="home-menu">
    //         <h1>Atlas Monitoring</h1>
    //         <ul>
    //             <li><a href='monitor'>Monitor</a></li>
    //             <li><a href='graphics'>Graphics</a></li>
    //         </ul>
    //         <div className="home-card-footer">
    //             <img src={logo} className='home-menu-logo'></img>
    //         </div>
            
    //     </div>
        
        
    // </div>
}