import React from 'react';
import './App.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Monitor } from '../pages/Monitor';
import { HomePage } from '../pages/HomePage';
import { Tables } from '../pages/Tables';


const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <div className="App grid">
        <HomePage></HomePage>
      </div>
    )
  },
  {
    path: "monitor/",
    element: (
      <div className="App grid">
        
        <Monitor></Monitor>
      </div>
    )
  }
  ,
  {
    path: "tables/",
    element: (
      <div className="App grid">
        
        <Tables></Tables>
      </div>
    )
  }
])

function App() {
  return <RouterProvider router={router}></RouterProvider>
}

export default App;
