import { configureStore } from '@reduxjs/toolkit';
import { ConfigReducer } from './features/Config/ConfigSlice';
import { MQTTReducer } from './features/MQTT/mqtt_slicer';
import { ContextReducer } from './features/PageContext/ContextSlice';




const store = configureStore({
    reducer:{
        mqtt: MQTTReducer,
        context:ContextReducer,
        options: ConfigReducer
    }
});

export default store;