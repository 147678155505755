
import { useSelector } from "react-redux";
import { getTopicName } from "../../utils"
import { ReactTabulator } from 'react-tabulator'
import { CustomHeader } from "../../components/CustomHeader";
import { SideMenu } from "../../components/SideMenu";
import { VariableSelector, ElementTypeSelector, GroupSelector } from "../../components/SideMenu/Selector";
import MQTTComponent from "../../components/MQTT"
import React from "react";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import store from "../../store";
import { useEffect, useState } from "react";

import './main-content.css'

function refresh(key){
    const {mqtt} = store.getState()
    return mqtt[key]
}
store.subscribe(refresh)


export function Tables(props){
    const {active, sideMenu} = useSelector((state) => state.context)
    
    const {elementType, plantName} = active

    const key = getTopicName(plantName, elementType)
    const [raw_data, setRawData] = useState({})


    const showMenu = (state) => {
        if (state){
            if(elementType === 'Inverter'){
                return <SideMenu><VariableSelector></VariableSelector></SideMenu>
            // }else if(elementType === 'Tracker'){
            //     return <SideMenu><GroupSelector></GroupSelector></SideMenu>
            }else{
                return <SideMenu><ElementTypeSelector></ElementTypeSelector></SideMenu>
            }
        }
    }

    var set_columns = new Set(['name'])
    var data = []

    for (var i in raw_data) {
        var v= {'name':i, ...raw_data[i]}
        data.push(v)

        for (var d in raw_data[i]) {
            set_columns.add(d)
        }
    }

    var columns = []
    set_columns.forEach((v, i)=>{
        if (v.toLowerCase() === 'name') {
            columns.push({ title: v, field: v, headerFilter: 'input'})
        }else{
            columns.push({ title: v, field: v, headerFilter: 'input', headerFilterFunc:'>='})
        }
        
    })

    const onRefresh = ()=>{
        setRawData(refresh(key))
    }
    var singleClickDelayTimer = null
    var onlyOneColumnExpanded = false; 
    let ref = React.useRef();  


    
    return <>
        <CustomHeader></CustomHeader>
        <MQTTComponent val='one'></MQTTComponent>


        
        <div id="tables" style={{}}>
            {showMenu(sideMenu)}
            <div style={{width:"100%", justifyContent:"flex-start", alignItems:"flex-start", display:'flex', flexDirection:"column", maxWidth:"99vw",overflow:'auto'}}>
                <button onClick={onRefresh}>refresh</button>
                <div>
                    <ReactTabulator
                        onRef={(r) => (ref = r)}
                        data={data}
                        columns={columns}
                        layout={"fitData"}
                        options={{ movableColumns: true, movableRows: false }}
                        events={{
                            headerClick: (e, column) => {
                                //e - the click event object - left click
                                //column - column component
                                let table = ref.current
                        
                                if (e.target.className.includes("arrow")) {
                                    //user clicked on the sort arrow icon
                                    return null;
                                }
                        
                                if (singleClickDelayTimer !== null){
                                    //double click
                                    clearTimeout(singleClickDelayTimer);
                                    singleClickDelayTimer = null;
                        
                                    //double click action:
                                    //collapses all other columns
                                    //or expands all columns  (like plotly)
                                    let thisColumnName = column.getDefinition().title;
                        
                                    if (!onlyOneColumnExpanded) {
                                        for(let otherColumn of table.getColumns()){
                                            if (otherColumn.getDefinition().title != thisColumnName) {
                                                otherColumn.setWidth(10);
                                            } else {
                                                otherColumn.setWidth(true);
                                            }
                                        }
                                        onlyOneColumnExpanded = true;
                                    } else {
                                        for(let otherColumn of table.getColumns()){
                                            otherColumn.setWidth(true);
                                        }
                                        onlyOneColumnExpanded = false;
                                    }
                        
                                }else{
                                    singleClickDelayTimer = setTimeout(() => {
                                        //single click
                                        singleClickDelayTimer = null;
                        
                                        //single click action:
                                        //collapses/expands column
                                        if (column.getWidth() <= 40){
                                            column.setWidth(true);
                                        } else {
                                            column.setWidth(40);
                                        }
                        
                                    }, 300);
                                }
                        
                                
                            },
                            headerContext: function(e, column){
                                //e - the click event object
                                //column - column component
                                
                                //right mouse button click
                                //puts column at the right side of Comms column
                                
                                //stop browser default context menu from opening
                                e.preventDefault();
                        
                                if (!("Element;Timestamp;Comms".includes(column.getDefinition().title))) {
                                    column.move("comms", true);
                                }
                            }
                        }}
                    />
                </div>
                
            </div>
        </div>
    </>
}
