import { useDispatch, useSelector } from "react-redux"
import { getTopicName } from "../../../utils"
import { BigCard } from "../BigCard"
import { HorizontalBar } from "../../../components/HorizontalBar"
import './show-data.css'
import { Graphics } from "../../../components/Graphics"
import { HVMapping, isHVMapped } from "../../../components/HVMapping"
import MQTTComponent from "../../../components/MQTT"
import { color } from "../../../utils/colors"





const generalOptions = (data) => {
        if (data !== undefined){
            const keys = Object.keys(data)
        
            const x = keys.map((v, i) =>{
                const variables = Object.keys(data[v])
                // console.log(data[v])
                return <div key={v} className="element-display generalOption">
                        <span className="element-display-header">{v}</span>
                            {variables.map((vars) => { 
                                if((vars !== "ts") && (vars !== 'comms')){
                                    return  <div className="element-display-value ">
                                        <span className="variable-label">{vars}</span>
                                        <span className="variable-value">{Number(data[v][vars]).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                        })}</span>
                                    </div>
                                    // .toFixed(1)
                                }else{
                                    return undefined
                                }
                            })} 
                    </div>   
                })
            return x
        }else{
            return undefined
        }
    }

const networkOptions = (data, plantName, plants) => {
        const lat_threshold = 2000
        if (data !== undefined){
            let plantsNames = plants.concat(["CasaBlanca"]).sort()
            const keys = Object.keys(data)
            console.log()
            
            
            if (plantName === 'Atlas'){
                const x = plantsNames.map((plant, i) =>{
                    const plant_filter = (name) => {
                        let r = false
                        let key = plant.toLowerCase().replace(" ","_").replace(" ","_")
                        console.log(key)
                        if (name.includes(key)){
                            r = true
                        }
                        return r
                    }
                    
                    let x = keys.filter(plant_filter)
                    
                    if (x.length === 0){
                        return undefined
                    }
                    return <div key={plant} className="element-display generalOption" style={{"min-height":"195px"}}>
                            <div className="display-header-div"><span className="element-display-header">{plant}</span> </div>

                            {
                                
                                x.map(v => {
                                    const vars = 'latency'
                                    const latency = data[v][vars]

                                    let marker = <span className="dot-green"></span>
                                    if (latency > lat_threshold){
                                        marker = <span className="dot-red"></span>
                                    }
                                    
                                    return <>
                                    <div className="element-display-value ">
                                        <span className="variable-label" style={{"margin-right":"10px"}}>{v}</span>
                                        <span className="variable-value">{Number(data[v][vars]).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                        })} {vars==='latency'?'ms':null} {marker}</span>
                                        
                                    </div>
                                    </>
                                })
                            }
                            
                    </div>   
                })
    
    
                return x

            }
            else{
                console.log(data)
                const x = keys.map((v, i) =>{
                    const variables = Object.keys(data[v])
                    
                    
                    return <div key={v} className="element-display generalOption">
                            <div className="display-header-div"><span className="element-display-header">{v}</span> </div>
                                {variables.map((vars) => { 
                                    let marker = null
                                    if (vars == "latency"){
                                        marker = <span className="dot-green"></span>
                                        const latency = data[v]['latency']
                                        if (latency > lat_threshold){
                                            marker = <span className="dot-red"></span>
                                        }
                                    } else if (vars.includes("status")){
                                        marker = <span className="dot-green"></span>
                                        const val = data[v][vars]
                                        if (val !== 1){
                                            marker = <span className="dot-red"></span>
                                        }
                                    }

                                    
                    


                                    if((vars !== "ts") && (vars !== 'comms')){
                                        var value = Number(data[v][vars])
                                        if  (isNaN(value)){
                                            return  <div className="element-display-value ">
                                                <span className="variable-label">{vars}</span>
                                                <span className="variable-value">{data[v][vars]}</span>
                                            </div>
                                        }else{
                                            return  <div className="element-display-value ">
                                                <span className="variable-label">{vars}</span>
                                                <span className="variable-value">{value.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                                })} {vars==='latency'?'ms':null}</span>
                                                {marker}
                                            </div>
                                        }
                                        
                                        // .toFixed(1)
                                    }else{
                                        return undefined
                                    }
                                })} 
                    </div>   
                })
    
    
                return x
            }
            
        }else{
            return undefined
        }
    }
const ITS_VARS_HIGHLIGHT = {
    tsa_on : true,
    major_circuit_alarm : false,
    minor_circuit_alarm : false,
    oil_temp_alarm : false,
    gas_alarm : false,
    oil_temp_trip : false,
    gas_trip : false,
    oil_temp_fan : true,
    oil_low_level_trip : false,
    pressure_trip : false,
    ac_dc_converter_ok: true,
    F30_IMAX_alarm : false,
    F31_IMAX_alarm : false,
    oil_high_level_alarm : false,
    fan_breaker_on: true,
    fan_contactor_on : true,
    oil_low_level_alarm:false,
    pressure_alarm: false,
    switch_node_1: true,
    switch_node_2: true,
    breaker_node: true,
    disconnector_node: true,
    overcurrent_trip: false,
    its_switch_node_1 : true, 
    _its_switch_node_2 : true, 
    _its_breaker_node : true, 
    _its_disconnector_node : true
}

const ITS_STEPS = {
    oilT : [95, 90],
    windingT : [110, 100]
}

const itsOptions = (data, plantName, plants) => {
        const lat_threshold = 2000
        if (data !== undefined){
            let plantsNames = plants.concat(["CasaBlanca"]).sort()
            const keys = Object.keys(data)
            console.log()
            

            console.log(data)
            const x = keys.map((v, i) =>{
                const variables = Object.keys(data[v])
                
                
                return <div key={v} className="element-display generalOption">
                        <div className="display-header-div"><span className="element-display-header">{v}</span> </div>
                            {variables.map((vars) => { 

                                let marker = null
                                if (vars in ITS_VARS_HIGHLIGHT){
                                    marker = <span className="dot-green"></span>
                                    const val = data[v][vars]
                                    var crit = ITS_VARS_HIGHLIGHT[vars]
                                    if (typeof crit === 'string'){
                                        crit = data[v][crit]
                                    }
                                    if (val !== crit){
                                        if ((vars === 'fan_contactor_on')||(vars === 'oil_temp_fan')){
                                            marker = <span className="dot-yellow"></span>
                                        }else{
                                            marker = <span className="dot-red"></span>
                                        }
                                        
                                    }
                                } else if (vars in ITS_STEPS){
                                    marker = <span className="dot-green"></span>
                                    const val = data[v][vars]
                                    var crit = ITS_STEPS[vars]
                                    if (crit.length == 2) {
                                        if (val >= crit[0]){
                                            marker = <span className="dot-red"></span>
                                        }else if(val >= crit[1]){
                                            marker = <span className="dot-yellow"></span>
                                        }                                      
                                    } else if (crit.length == 1) {
                                        if (val >= crit[0]){
                                            marker = <span className="dot-red"></span>
                                        }                                    
                                    }
                                    
                                }

                                
                


                                if((vars !== "ts") && (vars !== 'comms')){
                                    var value = Number(data[v][vars])
                                    if  (isNaN(value)){
                                        return  <div className="element-display-value ">
                                            <span className="variable-label">{vars}</span>
                                            <span className="variable-value">{data[v][vars]}</span>
                                        </div>
                                    }else{
                                        return  <div className="element-display-value ">
                                            <span className="variable-label">{vars}</span>
                                            <span className="variable-value">{value.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                            })}</span>
                                            {marker}
                                        </div>
                                    }
                                    
                                    // .toFixed(1)
                                }else{
                                    return undefined
                                }
                            })} 
                </div>   
            })

    
                return x
            
        }else{
            return undefined
        }
    }

const inverterOptions = (data, variable, updateSelection, mapMax, wList) => {
    // console.log(data, variable, updateSelection, mapMax)
    if (data !== undefined){
        // console.log(data, variable, updateSelection, mapMax)

        const elements = Object.keys(data)
        let itsMapping = {}
        elements.forEach((element) => {
            const a = element.split('-')
            const its = a[0] + '-' + a[1]

            if (!(its in itsMapping)){
                itsMapping[its] = []
            }
            itsMapping[its].push(element)
        })

        // console.log(itsMapping)
        
    
        const options = Object.keys(itsMapping).map((its, i) => {
            // const wList = ["FWord1", "FWord2", "FWord3", "FWord4", "WWord1", "WWord2", "WWord3"]
            // console.log(itsMapping[its])
            const invsInfo = itsMapping[its].sort().map((invName, i) => {
                let warnings = 0
                const info = data[invName][variable]
                wList.forEach((v, i) => {
                    if (data[invName][v] > 0){
                        warnings = warnings + 1
                    }                    
                })
                let sat = false
                
                let vmin = 0
                if(variable){
                    if(variable.includes('_temp')&&(info > 90)){
                        sat = true
                    }
                    if ((variable === "Q")|(variable === "Q_set")){
                        vmin = -mapMax[variable]
                        if (info > mapMax[variable]*0.1){
                            sat = false
                        }
                    }
                }

                

                const alert = warnings > 0
                if (info !== undefined){
                    if (("Pdc_M1" in data[invName])&&("Pdc_M2" in data[invName])){
                        if ((data[invName]["P"] >= 50)&&((data[invName]["Pdc_M1"] < 0.1)||(data[invName]["Pdc_M2"] < 0.1))){
                            return <>
                                <HorizontalBar sat={sat} comms = {data[invName].comms === 0} notificate={true} onClick = {()=>{updateSelection(invName)}} elementName={invName} value={info} minValue={vmin} maxValue={mapMax[variable]} title={invName  + " - MODULE UNAV. - (Pdc_M1 - " +data[invName]["Pdc_M1"].toFixed(1)+ ", Pdc_M2 - "+data[invName]["Pdc_M2"].toFixed(1) + ")" + variable +  " - " + info.toFixed(1)}></HorizontalBar>
                            </>
                        }
                    }
                    return <>
                        <HorizontalBar sat={sat} comms = {data[invName].comms === 0} notificate={alert} onClick = {()=>{updateSelection(invName)}} elementName={invName} value={info} minValue={vmin} maxValue={mapMax[variable]} title={invName  + " - " + variable +  " - " + info.toFixed(1)}></HorizontalBar>
                    </>
                }else{
                return <HorizontalBar sat={sat} comms = {data[invName].comms === 0} notificate={alert} onClick = {()=>{updateSelection(invName)}} elementName={invName} value={info} minValue={vmin} maxValue={mapMax[variable]} title={invName  + " - Missing Data (COMMS "+ data[invName].comms + ")"}></HorizontalBar>
                }
                
            })
            return (
                <div key={its} className="element-display" style={{minWidth: 100}}>
                    <span  className="element-display-header">{its}</span>
                    
                    <div className="its-info">
                        {invsInfo}
                    </div>
                </div>)
        })

        return options
    }else{
        return undefined
    }
}    
const inverterSelection = (data, elementName, plantName) => {
    // console.log(data, elementName)
    if ((elementName !== undefined)&(elementName !== undefined)&(data !== undefined)){
        const info = data[elementName]
        return <BigCard plantName = {plantName} elementName = {elementName} info={info}></BigCard>
    }else{
        return <></>
    }
    
}

function ShowData(props){

    // const [elementName, setElementName] = useState(undefined)
    const {plantsNames, mapMax, plantInvertersType, faultMap} = useSelector(state => state.options)
    const {plantName, elementType, elementName, variable} = props.context
    const inverterType = plantInvertersType[plantName]
    const warnMapping = faultMap[inverterType]
    
    const dispatch = useDispatch()
    const key = getTopicName(plantName, elementType)
    
    const data = useSelector(state => state.mqtt[key])
    const updateSelection = (value) => {
        dispatch({
            type: 'context/toggleElementName',
            payload: value
        })
    }
    let title = `${plantName} - ${elementType} - ${variable}`
    let options = <></>
    let selection =<>{elementName}</>

    const getClass = ()=>{
        if ((elementType === 'Inverter') && (elementName!== undefined)){
            return "shared"
        }else{
            return "full"
        }
        
    }

    if ((elementType === 'Inverter') && (data !== undefined)){
        // console.log('Inverter')
        options = <div id="showdata-space" className={getClass()}>
            {inverterOptions(data, variable, updateSelection, mapMax[plantName][elementType], Object.keys(warnMapping))}
        </div>
        selection = <div id="showdata-space" className={getClass()}>
        <div id="selected-element-content">
            
            <div id="selected-element-header">
                <h2>Inverter {elementName}</h2>
                <button onClick={()=>{updateSelection(undefined)}}>x</button>
            </div>
            <div className="alignHorizontal">
                <Graphics style={{width:400, height:200}} config = {{plantName, elementType, elementName, variables:['P']}}></Graphics>
                {inverterSelection(data, elementName, plantName)}
            </div>
            
        </div>
        </div>
    }else if(((elementType === 'Network')||(elementType === 'CCTV'))&& (data !== undefined)){
        options = <div id="showdata-space" className={getClass()}>
            {networkOptions(data ,plantName,plantsNames)}
        </div>
    }else if((elementType === 'Its') && (data !== undefined)){
        options = <div id="showdata-space" className={getClass()}>
            {itsOptions(data ,plantName,plantsNames)}
        </div>
    // }
    // else if((elementType === 'HV') && (data !== undefined)){
    //     options = isHVMapped(plantName) ? <HVMapping plantName={plantName} data={data}></HVMapping>: generalOptions(data)
    }else{
        // console.log('General')
        options = <div id="showdata-space" className={getClass()}>
            {generalOptions(data)}
        </div>
        
    }

    if(elementType !== 'Inverter'){
        title = `${plantName} - ${elementType}`
    }

   
    // console.log(options)
        
    // alert('oi2')////;
    return <div id="showdata" style={props.style}>
            <MQTTComponent val='one'></MQTTComponent>
            <header>
            <h1>{title}</h1>
            </header>
            
            {elementName? selection:<></>}
            
            <div id="display-info" className={getClass()}>
                {options}                
            </div>
        </div>
    
}


export default ShowData

