import { useDispatch, useSelector } from "react-redux";
import "./side-menu.css"

export function SideMenu(props){
    const {sideMenu} = useSelector((state=>state.context))
    const dispatch = useDispatch()

    return <div id="side-menu">
            <div id="search-card">
            {sideMenu}
            <div id="search-card-close"><button onClick={()=>{
                dispatch({
                    type: 'context/toggleSideMenu',
                    payload: false
                 })
            }}>x</button></div>
            {props.children}
            </div>
        
        </div>
}