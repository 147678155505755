import { useEffect} from "react"
import { useDispatch, useSelector } from "react-redux"
import "./selector.css"


export function VariableSelector(){
    const {context, options} = useSelector(state => state)
    const {plantName, elementType, variable} = context.active

    const dispatch = useDispatch()

    const plantOptions = options.plantsNames.map(plantName =>{
        return <option key={plantName} value={plantName}>{plantName}</option>
    })
    const typesOptions = options.elementsTypes.map(elementType =>{
        return <option key={elementType} value={elementType}>{elementType}</option>
    })
    const varsMapping = options.mappingElementTypeVariables[elementType]
    const variables = (varsMapping.default.concat(varsMapping[plantName]?varsMapping[plantName]:[]))
    let varsOptions = <></> 
    if (varsMapping){
        varsOptions = variables.map(variable =>{
            return <option key={variable} value={variable}>{variable}</option>
        })
    }  


    const togglePlantName = (plantName) =>{
        dispatch({
            type: 'context/toggleActiveContext',
            payload: {plantName, variable:undefined, elementName:undefined}
        })
    }
    const toggleElementType = (elementType) =>{
        dispatch({
            type: 'context/toggleActiveContext',
            payload: {elementType, variable:undefined, elementName:undefined}
        })
    }
    const toggleVariable = (variable) =>{
        dispatch({
            type: 'context/toggleVariable',
            payload: variable
        })
    }

    useEffect(()=>{
        if(!variable){
            toggleVariable(variables[0])
        }
    })
    return (
        <div id='selector'>
            <select name="plants" id="plants" value={plantName} onChange = {(event) => togglePlantName(event.target.value)}>
                {plantOptions}
            </select>

            <select name="elementsTypes" id="elementsTypes" value={elementType} onChange = {(event) => toggleElementType(event.target.value)}>
                {typesOptions}
            </select>

            <select name="variables" id="variables" value={variable} onChange = {(event) => toggleVariable(event.target.value)}>
                <option>Select a Variable</option>
                {varsOptions}
            </select>
            {/* <button onClick ={clickSelect}>Select</button> */}
        </div>
    )
}


// export function GroupSelector(){
//     const {context, options} = useSelector(state => state)
//     const data = useSelector(state => state.mqtt[key])
//     const {plantName, elementType, group } = context.active

//     console.log(data)

//     const dispatch = useDispatch()

//     const plantOptions = options.plantsNames.map(plantName =>{
//         return <option key={plantName} value={plantName}>{plantName}</option>
//     })
//     const typesOptions = options.elementsTypes.map(elementType =>{
//         return <option key={elementType} value={elementType}>{elementType}</option>
//     })
//     const groupsOptions = options.elementsTypes.map(elementType =>{
//         return <option key={elementType} value={elementType}>{elementType}</option>
//     })


//     const togglePlantName = (plantName) =>{
//         dispatch({
//             type: 'context/toggleActiveContext',
//             payload: {plantName, variable:undefined, elementName:undefined}
//         })
//     }
//     const toggleElementType = (elementType) =>{
//         dispatch({
//             type: 'context/toggleActiveContext',
//             payload: {elementType, variable:undefined, elementName:undefined}
//         })
//     }
//     const toggleGroup = (variable) =>{
//         dispatch({
//             type: 'context/toggleGroup',
//             payload: variable
//         })
//     }

//     useEffect(()=>{
//         if(!group){
//             toggleGroup('1-1')
//         }
//     })
//     return (
//         <div id='selector'>
//             <select name="plants" id="plants" value={plantName} onChange = {(event) => togglePlantName(event.target.value)}>
//                 {plantOptions}
//             </select>

//             <select name="elementsTypes" id="elementsTypes" value={elementType} onChange = {(event) => toggleElementType(event.target.value)}>
//                 {typesOptions}
//             </select>

//             <select name="group" id="group" value={group} onChange = {(event) => toggleGroup(event.target.value)}>
//                 <option>Select a Group</option>
//                 {varsOptions}
//             </select>
//             {/* <button onClick ={clickSelect}>Select</button> */}
//         </div>
//     )
// }


export function ElementTypeSelector(props){
    const {context, options} = useSelector(state => state)
    const {plantName, elementType} = context.active

    const dispatch = useDispatch()

    const plantOptions = options.plantsNames.map(plantName =>{
        return <option key={plantName} value={plantName}>{plantName}</option>
    })

    const typesOptions = options.elementsTypes.map(elementType =>{
        return <option key={elementType} value={elementType}>{elementType}</option>
    })


    const togglePlantName = (plantName) =>{
        dispatch({
            type: 'context/togglePlantName',
            payload: plantName
        })
    }
    const toggleElementType = (elementType) =>{
        dispatch({
            type: 'context/toggleElementType',
            payload: elementType
        })
    }

    return (
        <div id='selector'>
            <select name="plants" id="plants" value={plantName} onChange = {(event) => togglePlantName(event.target.value)}>
                {plantOptions}
            </select>

            <select name="elementsTypes" id="elementsTypes" value={elementType} onChange = {(event) => toggleElementType(event.target.value)}>
                {typesOptions}
            </select>
        </div>
    )
}