import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    active: {
        plantName: 'Jacaranda',
        elementType: 'Inverter',
        elementName: undefined,
        group: undefined,
        variable: 'P'
    },
    sideMenu: false
}



export const contextSlice = createSlice({
    name: 'context',
    initialState,
    reducers: {
        togglePlantName: (state, action) => {
            state.active.plantName = action.payload
        },

        toggleElementType: (state, action) => {
            state.active.elementType = action.payload
        },

        toggleElementName: (state, action) => {
            state.active.elementName = action.payload
        },
        toggleGroup: (state, action) => {
            state.active.variable = action.payload
        },
        toggleVariable: (state, action) => {
            state.active.variable = action.payload
        },
        toggleActiveContext: (state, action) => {
            state.active = {...state.active, ...action.payload}
        },
        toggleSideMenu: (state, action) => {
            state.sideMenu = action.payload
        }

    }
})

export const {
        togglePlantName, toggleElementType, toggleVariable, 
        toggleActiveContext, toggleElementName, toggleSideMenu
    } = contextSlice.actions
export const ContextReducer = contextSlice.reducer