import React from 'react';
// import { LineChart } from './charts';
import Plot from 'react-plotly.js';
import { color } from '../../utils/colors';

const formatDate = (date) =>{
    const Y = date.getFullYear().toString()
    const m = (date.getMonth() + 1).toString().padStart(2, '0')
    const d = date.getDay().toString().padStart(2, '0')
    const H = date.getHours().toString().padStart(2, '0')
    const M = date.getMinutes().toString().padStart(2, '0')
    const S = date.getSeconds().toString().padStart(2, '0')
    const MS = date.getMilliseconds().toString().padStart(3, '0')
    return Y + "-" + m + "-" + d + " " + H + ":" + M +":" + S + "." + MS
}

const resample = (l1, l2, freq=1) => {
    if (l1.length <=freq){
        return [l1,l2]
    }
    const [a, b] = [[], []]

    let i = 0
    while (i < l1.length){
        a.push(l1[i])
        const c = l2.slice(i, i + freq)
        b.push(c.reduce((acc, nv) => acc + nv) / c.length)

        i = i + freq
    }
    
    return [a, b]
}

// const format = (ts, vals) => {
//     const time = ts.map((v, i)=>{
//             const date = new Date(v * 1000)
//             return v * 1000
//         })
//     let a = []
//     let i = 0
//     while (i < time.length){
//         a.push({ts:time[i], val:vals[i]})
//         i = i + 1
//     }
    
//     return a
// }



// export default function LineChar (props){
    
//     const {samples, style} = props
    
//     let info = []
//     const maxPoints = 100
//     if (samples){
        
//         Object.keys(samples).forEach(variable =>{

//             const [time, vals] = resample(samples[variable].ts.slice(-maxPoints), samples[variable].val.slice(-maxPoints))
//             info.push(format(time, vals))
//         })
//     }
    
//     if (info.length === 0){
//         return undefined
//     }
//     console.log(info)
//     // const frac = 0.5
//     // const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
//     const id="my-svg"
//     LineChart(info[0], {
//         id,
//         x: d => d.ts,
//         y: d => d.val,
//         marginLeft: 50,
//         ylabel: "Inverter - P",
//     })
//     return (
//     <div style={style} className='graphics'>
//         <svg id={id}></svg>
//     </div>
//     )
// }


export default function LineChar (props){
    
    const {samples, style} = props
    
    let info = []
    const maxPoints = 100
    if (samples){
        
        Object.keys(samples).forEach(variable =>{

            const [time, val] = resample(samples[variable].ts.slice(-maxPoints), samples[variable].val.slice(-maxPoints))
            info.push({
                x: time.map((v, i)=>{
                    const date = new Date(v * 1000)
                    return formatDate(date)
                }),
                y: val,
                type: 'scatter',
                mode: 'lines',
                name: variable,
            })
        })
    }
    
    if (info.length === 0){
        return undefined
    }
    console.log(info)
    // const frac = 0.5
    // const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    return (
    <div style={style} className='graphics'>
        <Plot
        data={info}
        layout={ {...style, autosize: true,
            margin: {l:50, r:20, t:20, b:20},
            font: {
                family: 'Montserrat',
                size: 13,
                color: color.grey2
              },
            xaxis: {
            // title: 'Timestamp',
            type: 'date',
            tickformat: '%Y-%m-%d %H:%M:%S.%L',
            dtick: 'S'
          },} }
        // style = {{
        //     width: (vw-200)*0.95, height: vw*0.5, autosize: true, 
        // }}
        // onInitialized={(figure) => setFigure(figure)}
        // onUpdate={(figure) => console.log(figure)}
  />  
    </div>
    )
}
